.slide-item{
    width: 400px;    
}

.croche{
    margin-top: 50px;
    max-width: 1800px;
    margin-left: auto;
    margin-right: auto; 
    margin-bottom: 50px;
}

.listItems{
    font-size: 25px;
    list-style:inside disc;
}

.espacoDesc{
    letter-spacing: 3px;
    font-size: 18px;
    font-weight: bold;
    padding: 20px;
}

.sepGreen{
    color: rgba(73, 124, 67, 0.993);
    font-size: 22px;
}