.footer{
    bottom: 0;
    left: 0;
    right: 0;
    background: #111;
    height: auto;
    width: 100%;
    font-family: "Open Sans";
    padding-top: 40px;
    color: white;
}

.footer-content{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}
.footer-content h3{
    font-size: 1.8rem;
    font-weight: 400;
    text-transform: capitalize;
    line-height: 3rem;
}
.footer-content p{
    max-width: 600px;
    margin: 10px auto;
    line-height:  28px;
    font-size: 14px;
}

.contato{
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0;
}
.contato li{
    margin: 0 10px;
}
.contato li a{
    text-decoration: none;
    color: white;
    font-size: 13px;
}
.contato a i{
    color: rgb(1, 153, 1);
    font-size: 20px;
    vertical-align: middle;
    transition: color .4s ease;
    margin-right: 10px;
}

.socials{
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem 0 3rem 0;
}
.socials li{
    margin: 0 10px;
}
.socials a{
    text-decoration: none;
    color: white;
}
.socials a i{
    font-size: 1.1 rem;
    transition: color .4s ease;
}
.socials a:hover i{
    color: aqua;
}
.footer-bottom{
    background: #000;
    width: 100%;
    padding: 20px 0;
    text-align: center;
}
.footer-bottom p{
    font-size: 14px;
    word-spacing: 2px;
}
.footer-bottom span{
    text-transform: uppercase;
    opacity: .4;
    font-weight: 200;
}
.footer-bottom a{
    text-decoration: none;
    color: white;
}

.whatsapp{
    position:fixed;
    width:50px;
    height:50px;
    bottom:50px;
    right:50px;
    border-radius:50px;
    text-align:center;
    font-size:30px;
    z-index:999997;
    box-shadow: 1px 1px 2px #888;
}
.instagram{
    position:fixed;
    width:50px;
    height:50px;
    bottom:110px;
    right:55px;
    border-radius:50px;
    text-align:center;
    font-size:30px;
    z-index:999997;
    box-shadow: 1px 1px 2px #888;
}
.instagram img{
    width: 60px;
}

@media screen and (max-width: 768px){ 
    .footer-content p{
        max-width: 340px;
    }
    .footer-bottom p{
        font-size: 12px;
    }
    .contato li p{
        font-size: 12px;
    }

    .whatsapp{
        bottom: 50px;
        right:20px;
    }
    .instagram{
        bottom: 110px;
        right:25px;
    }
}