section{
    background-color: rgba(255, 255, 255, 0);
}

#servicos{
    padding-top: 50px;
}
.tituloInicio{
    color: rgb(75, 75, 75);
    margin-top: 10px;
    margin-bottom: 80px;
}
.titulo hr{
    max-width: 40%;    
    margin: 0 auto;
    margin-top: 20px;
}

.linkProd{
    text-decoration: none;
}
@media screen and (max-width: 768px){     
    .row {
        width: 100%;
        display: grid;
        margin: 0 auto;
    }

    .tituloInicio{
        margin-top: 50px;
    }
}