.card {
    border-radius: 5px;
    box-shadow: 7px 7px 13px 0px rgba(50, 50, 50, 0.22);
    padding: 30px;
    margin: 20px;
    width: 350px;
    transition: all 0.3s ease-out;    
  }

  .card:hover {
    transform: translateY(-5px);
    cursor: pointer;
  }
   
  .card h2 {
    color: #202020;
    font-size: 26px;
    margin: 0px auto;
  }

  .card p {
    color: #a3a5ae;
    font-size: 16px;
  }
   
  .image {
    border-radius: 50%;
    width: 200px;
    height: 200px;
  }

  .blue {
    border-left: 3px solid #4895ff;
  }
   
  .green {
    border-left: 3px solid #3bb54a;
  }
   
  .red {
    border-left: 3px solid #b3404a;
  }