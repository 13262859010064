.content-essere{
    
}
.essere{
    width: 100%;
    height: 570px;
    align-items: center;
    display: flex;
    background-color: #ce2b2b75;
    margin-top: 100px;
}
.leftImg{
    width: 40%;
    height: 100%;
    background-image: url('../../assets/images/1.png');
    background-size: 130%;
    background-position: center;
    background-repeat: no-repeat;
}

.rightBoard{
    width: 60%;
}
.btnAgendarInst{
    width: 50%;
    height: 60px;
    border: 4px solid rgba(248, 248, 248, 0.61);
    background-color: rgb(132, 139, 132);
    color: rgb(255, 255, 255);
    font-size: x-large;
    cursor:pointer;
}
.dizeres{
    font-size: 25px;
    color: rgb(255, 255, 255);
    font-style: italic;
    padding: 20px;
}
.logoText{
    flex: 1; /* isto é para a nav ocupar a largura toda menos a logo */
   justify-content: center; /* alinha horizontalmente */
   align-items: center; 
}

.titulo{
    margin-top: 50px;
    margin-bottom: 50px;
    font-size: xx-large;
    color: rgb(255, 255, 255);
}
.titulo hr{
    max-width: 40%;    
    margin: 0 auto;
    margin-top: 20px;
}
.item-profissional {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  .texto-profissional{
      margin-left: 20px;
  }
  .texto-profissional h2{
    color: rgb(36, 36, 36);
}
  .texto-profissional p{
      padding-top: 10px;
      color: rgb(66, 66, 66);
      max-width: 500px;
  }

  .image-profissional {
    width: 500px;
  }

  .margen{
    margin-left: 20px;
    margin-right: 20px;
  }

  @media screen and (max-width: 768px){ 
    .image-profissional {
        width: 150px;
        height: 150px;
    }

    .essere{
        
    }

    .instrutores{
        height: 400px;
        align-items: center;
        display: flex;
        background-color: #458459;
        margin-top: -30px;
    }
    
    .item-profissional {

        margin: 20px 20px;
    }

    .leftImg{
        display: none;
        background-image: none;
    }
    .leftImgMobile{
        width: 100%;
        height: 400px;
        background-image: url('../../assets/images/1.png');
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
        margin-bottom: -105px;
    }

    .rightBoard{
        width: 100%;
        margin-top: -20px;
    }

    .btnAgendarInst{
        width: 90%;
        font-size: 18px;
    }

}