

.separator{
    width: 100px;
    margin-top: 10px;
    margin-bottom: 10px;
}


.menu{ 
    height: 650px;
    //border-bottom: 5px solid rgba(214, 214, 214, 0.178);
    background-image: url('../../assets/images/background.png');
    //background-color: #487958;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-bottom: 4px solid #4879588f
}

.beneficios{
    padding-top: 100px;
}

.btn{
    padding-bottom: 100px;
}


.titgrey{
    color: rgb(122, 122, 122);
    font-size: 25px;
}

.titgreen{
    color: rgb(16, 126, 16);
    font-size: 40px;
    font-weight: bold;
}


.row {
    display: flex;
    justify-content: center;
    align-items: center;    
  }

.btnProd{
    width: 35%;
    margin-left: auto;
    margin-right: auto; 
    float: inherit;
    height: 60px;
    background-color: rgba(52, 117, 79, 0.993);
    color: white;
    border: 4px solid rgba(248, 248, 248, 0.61);
    font-size: x-large;
    cursor:pointer;
}
.logo img{
    width: 500px;
    //background-color: rgba(255, 255, 255, 0.548);
    //border-radius: 50%;
    //background-image: radial-gradient( #ffffff00, #ffffff3b);
}

.content{
    margin: 0;
    padding-top: 50px;    
    color: rgb(49, 49, 49);    
    background-color: rgba(255, 255, 255, 0.89);
}

.titulo hr{
    max-width: 40%;    
    margin: 0 auto;
    margin-top: 20px;
}


.imgStudio{
    width: 400px;
    float: left;
    margin-top: -10px;
}

.studio{
    text-align: left;
    margin-top: 80px;
    font-size: 18px;
    margin-left: 20px;
    margin-right: 20px;;
}

.greenback{
    background: #487958;
}

.ulObjetivos{
    margin-left: 500px;
}

.imgLogoIcon{
    width: 100px;
}

@media screen and (max-width: 768px){ 
    .content{
        padding: 40px 10px 0 0;
        width: 100%;
    }
    .footer{
      width: 100%;
      height: 100px;
    }
    .content p{
        font-size: 22px;
    }
    .logo img{
        width: 80%;
        margin-top: 25px;
    }
    .imgStudio{
        width: 0%;
    }


    .btnProd{
        width: 70%;
        margin-left: auto;
        margin-right: auto; 
        float: inherit;
        height: 55px;
        font-size: 98%;
    }

    .separator{
        width: 100px;
    }
    
}